<template>
  <div class="pt-3">
    <b-container v-if="article" :fluid="article.full_width">
      <h1>{{ article.title }}</h1>

      <h6 v-if="article.authors.length != 0" class="text-muted">
        By {{ article.authors.join(", ") }}
      </h6>

      <b-img-lazy
        v-if="article.show_thumbnail"
        fluid
        :src="article.thumbnail_url"
        class="mb-3"
      />

      <hr />

      <span v-html="article.html" />

      <hr />
      <hr />
      <h3>Related Articles</h3>
      <p v-if="related.length == 0">No related articles</p>
      <div v-for="relatedArticle in related" :key="relatedArticle.slug">
        <hr />
        <b-row
          @click="openArticle(relatedArticle.slug)"
          style="cursor: pointer"
        >
          <b-col v-if="relatedArticle.show_thumbnail" cols="*">
            <b-img-lazy
              fluid
              style="max-height: 75px"
              :src="relatedArticle.thumbnail_url"
              class="mb-3"
            />
          </b-col>

          <b-col>
            <h4>{{ relatedArticle.title }}</h4>
            <p>{{ relatedArticle.description }}</p>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      article: null,
      related: [],
    };
  },
  methods: {
    openArticle(slug) {
      this.$router.push({ path: `/articles/${slug}` });
      this.$router.go(this.$router.currentRoute);
    },
  },
  async created() {
    let response = await this.$http.get(
      this.$store.state.apiURL +
        "/articles/firm/" +
        this.$store.state.firmID +
        "/slug/" +
        this.id,
      null
    );
    this.article = response.data;
    this.$route.meta.title = this.article.title;

    // Add a temporary query parameter.
    this.$router.replace({ query: { temp: "temp" } });
    // Remove the temporary query parameter.
    this.$router.replace({ query: { temp: undefined } });

    let relatedArticles = await this.$http.get(
      this.$store.state.apiURL +
        "/articles/firm/" +
        this.$store.state.firmID +
        "/related/slug/" +
        this.id,
      null
    );
    this.related = relatedArticles.data;
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss";
</style>